import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import ogpImage from "../images/front_top_baseball_with_mock.jpg";

type OwnProps = {
    description?: string;
    lang?: string;
    meta?: any[];
    title: string;
};

type Props = OwnProps & typeof SEO.defaultProps;

function SEO ( { description, lang, meta, title }: Props ) {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            ogpImage
            siteUrl
          }
        }
      }
    `
    )

    const metaDescription = description || site.siteMetadata.description
    const ogpImageUrl = `${ site.siteMetadata.siteUrl }${ site.siteMetadata.ogpImage }`;

    return (
        <Helmet
            htmlAttributes={ {
                lang,
            } }
            title={ title }
            titleTemplate={ `%s | ${ site.siteMetadata.title }` }
            meta={ [
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: "og:image",
                    content: ogpImageUrl
                },
                {
                    property: "twitter:image",
                    content: ogpImageUrl
                },
                // {
                //     name: `twitter:card`,
                //     content: `summary`,
                // },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat( meta ) }
        >
            {/* { typeof window !== "undefined" && window.location.href.includes( "club-cloud.jp" ) &&
        <script data-ad-client="ca-pub-2063349347984250" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script> } */}
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `jp`,
    meta: [],
    description: ``,
}

export default SEO
